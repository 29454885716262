var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-slide-y-transition',[(_vm.isOpen)?_c('v-navigation-drawer',{staticClass:"overflow-hidden d-flex flex-column px-1",staticStyle:{"z-index":"203"},attrs:{"right":"","fixed":"","permanent":"","width":_vm.rootRoute.fullPath === '/' || _vm.isFullview
        ? '100vw'
        : _vm.$vuetify.breakpoint.md
        ? '45vw'
        : _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs
        ? '100vw'
        : '35vw'},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"d-flex flex-column overflow-auto",staticStyle:{"height":"100vh"}},[_c('app-breadcrumb',{staticClass:"mt-6 px-4",attrs:{"title":"Detail Pengguna","hide-breadcrumbs":"","onClickBack":_vm.handleBack},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.rootRoute?.fullPath !== '/')?_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? false : true),expression:"$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? false : true"}],staticClass:"primary--text mr-4",on:{"click":function($event){return _vm.handleOpenFullview()}}},[_vm._v(" "+_vm._s(_vm.isFullview ? _vm.icons.mdiFullscreenExit : _vm.icons.mdiFullscreen)+" ")]):_vm._e()]},proxy:true}],null,false,2385593442)}),_c('UserDetail',_vm._b({attrs:{"isFullview":_vm.isFullview}},'UserDetail',_vm.componentProps,false))],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }