<template>
  <v-card>
    <v-card-text class="pa-0 pt-2">
      <v-form>
        <v-row dense>
          <v-col
            cols="12"
            md="9"
          >
            <v-text-field
              v-model="automationDetail.name"
              label="Nama Workflow"
              placeholder="Input nama workflow"
              outlined
              dense
              hide-details="auto"
              :rules="[required]"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="pl-2"
          >
            <v-btn
              text
              outlined
              block
              color="primary"
            >
              Cara Penggunaan
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          dense
          class="mt-2"
        >
          <v-col cols="12">
            <!-- md="9" -->
            <span class="text-subtitle-2">Alur Workflow</span>

            <v-card
              class="mt-2"
              height="60vh"
              outlined
            >
              <div class="d-flex flex-column">
                <div class="ma-3">
                  <v-card outlined>
                    <v-card-title class="white--text pa-3">
                      <v-chip
                        label
                        large
                      >
                        <v-icon
                          class="mx-1"
                          size="24"
                        >
                          {{ icons.mdiContainStart }}
                        </v-icon>
                      </v-chip>
                      <p class="ml-4 mb-0">
                        Trigger
                        <workflow-field-info
                          title="Trigger"
                          :details="[
                            ' Trigger adalah aksi yang akan menjadi pemicu workflow dijalankan.',
                          ]"
                        />
                      </p>
                    </v-card-title>

                    <v-divider />

                    <v-card-text class="pa-3">
                      <v-row>
                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="mainObject"
                            outlined
                            flat
                            hide-details="auto"
                            label="Objek"
                            placeholder="Objek yang ingin dijadikan trigger"
                            :items="objectList"
                            item-text="name"
                            item-value="id"
                            return-object
                            required
                            @change="changeObject"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="automationDetail.group_operator"
                            :items="actionList"
                            outlined
                            flat
                            hide-details="auto"
                            label="Aksi"
                            placeholder="Aksi dari objek yang dijadikan trigger"
                            item-text="name_view"
                            item-value="id"
                            return-object
                            required
                            :loading="loadingAction"
                            :disabled="!mainObject"
                            @change="changeMainAction"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <!-- <div
                      v-if="trigger.action"
                      class="align-start px-3 mb-3"
                    >
                      <XyzTransition
                        appear
                        xyz="fade in-up-2 in-delay-2 in-stagger-1"
                      >
                        <template v-if="trigger.action.component_name">
                          <component
                            :is="trigger.action.component_name"
                            ref="filterAPI"
                            v-model="trigger.token"
                            :webhook-identifier="trigger.webhook_filter"
                            @updateWebhook="updateWebhook"
                          />
                        </template>
                      </XyzTransition>
                    </div> -->
                  </v-card>

                  <!-- <XyzTransitionGroup
                    appear
                    xyz="fade down-4 in-delay-2 in-stagger-1"
                  >
                    <div
                      v-if="trigger.action"
                      :key="0"
                      class="d-flex justify-center"
                    >
                      <v-icon
                        class="mx-1"
                        size="28"
                      >
                        {{ icons.mdiSourceCommit }}
                      </v-icon>
                    </div>
                    <v-card
                      v-if="trigger.action"
                      :key="1"
                      outlined
                    >
                      <v-skeleton-loader
                        v-if="loadingTriggerProperty"
                        :key="1"
                        type="list-item-avatar-three-line"
                      />
                      <template v-else>
                        <v-card-title class="white--text pa-3 d-flex justify-space-between">
                          <div class="d-flex align-center">
                            <v-chip
                              label
                              large
                            >
                              <v-icon
                                class="mx-1"
                                size="24"
                              >
                                {{ icons.mdiFilterOutline }}
                              </v-icon>
                            </v-chip>

                            <p class="ml-4 mb-0">Filter (Optional)</p>
                            <workflow-field-info
                              title="Filter"
                              :details="[
                                'Filter adalah kondisi yang akan menentukan apakah workflow akan dijalankan atau tidak.',
                                'Semua filter harus terpenuhi agar workflow dijalankan.',
                              ]"
                            />
                          </div>
                          <v-btn
                            color="primary"
                            outlined
                            icon
                            class="me-1"
                            @click="addFilter"
                          >
                            <v-icon size="30">
                              {{ icons.mdiPlus }}
                            </v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-divider class="pb-3" />
                        <XyzTransition
                          v-for="(filter, index) in filterList"
                          :key="index"
                          appear
                          xyz="fade left-4 duration-4"
                        >
                          <div class="pb-3 px-3">
                            <v-card
                              outlined
                              class="d-inline-flex"
                            >
                              <div class="d-flex align-center pa-2">
                                <v-icon
                                  size="22"
                                  class="mr-2 ms-1 text--disabled"
                                >
                                  {{ icons.mdiTuneVertical }}
                                </v-icon>
                                <workflow-filter-options
                                  ref="filterPropList"
                                  v-model="filter.trigger_property"
                                  :options="triggerPropertyList"
                                  @click="filter.operator = null"
                                >
                                  <v-chip
                                    label
                                    class="mr-2"
                                    medium
                                  >
                                    {{
                                      filter.trigger_property
                                        ? filter.trigger_property.name
                                        : 'Pilih Property'
                                    }}
                                  </v-chip>
                                </workflow-filter-options>
                                <workflow-filter-options
                                  v-if="filter.trigger_property"
                                  ref="filterOperatorList"
                                  v-model="filter.operator"
                                  appear
                                  xyz="fade left-4 duration-4"
                                  :options="
                                    operatorList.filter(
                                      el =>
                                        el.data_type.id === filter.trigger_property.data_type.id,
                                    )
                                  "
                                >
                                  <v-chip
                                    label
                                    class="mr-2"
                                  >
                                    {{ filter.operator ? filter.operator.value : 'Pilih Operator' }}
                                  </v-chip>
                                </workflow-filter-options>
                                <workflow-filter-field
                                  v-if="filter.trigger_property"
                                  v-model="filter.value"
                                  :filter="filter"
                                  :items="filter.trigger_property.options"
                                />
                                <v-chip
                                  label
                                  class="px-2 ms-3"
                                  @click="removeFilter(filter)"
                                >
                                  <v-icon size="20">
                                    {{ icons.mdiClose }}
                                  </v-icon>
                                </v-chip>
                              </div>
                            </v-card>
                          </div>
                        </XyzTransition>
                      </template>
                    </v-card>
                    <div
                      v-if="trigger.action"
                      :key="2"
                      class="d-flex justify-center"
                    >
                      <v-icon
                        class="mx-1"
                        size="28"
                      >
                        {{ icons.mdiSourceCommit }}
                      </v-icon>
                    </div>
                    <v-card
                      v-if="trigger.action"
                      :key="3"
                      outlined
                      class="mb-3"
                    >
                      <v-card-title class="white--text pa-3 d-flex justify-space-between">
                        <div class="d-flex align-center">
                          <v-chip
                            label
                            large
                          >
                            <v-icon
                              class="mx-1"
                              size="24"
                            >
                              {{ icons.mdiContainEnd }}
                            </v-icon>
                          </v-chip>

                          <p class="ml-4 mb-0">Action</p>
                          <workflow-field-info
                            title="Action"
                            :details="[
                              'Action adalah aksi / event yang akan dilakukan ketika workflow dijalankan.',
                            ]"
                          />
                        </div>
                        <v-btn
                          v-if="executorList.length < 3"
                          color="primary"
                          outlined
                          icon
                          class="me-1"
                          @click="addExecutor"
                        >
                          <v-icon size="30">
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-divider class="pb-3" />
                      <XyzTransition
                        v-for="(executor, index) in executorList"
                        :key="index"
                        appear
                        xyz="fade left-4 duration-4"
                      >
                        <v-card
                          outlined
                          class="mx-3 mb-3"
                        >
                          <div class="d-flex justify-end">
                            <v-chip
                              label
                              class="px-2 mt-2 me-2"
                              @click="removeExecutor(index)"
                            >
                              <v-icon size="20">
                                {{ icons.mdiClose }}
                              </v-icon>
                            </v-chip>
                          </div>

                          <v-card-text class="pa-3">
                            <v-row>
                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-select
                                  v-model="executor.object"
                                  flat
                                  dense
                                  outlined
                                  :items="objectList"
                                  item-text="name"
                                  item-value="id"
                                  placeholder="Pilih objek"
                                  hide-details="auto"
                                  return-object
                                  @change="val => changeExecutorObject(index, val)"
                                />
                              </v-col>
                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-select
                                  v-model="executor.action"
                                  flat
                                  :disabled="!executor.object"
                                  dense
                                  outlined
                                  :items="executorActionTemp[index]"
                                  item-text="name_view"
                                  item-value="id"
                                  placeholder="Pilih Aksi"
                                  hide-details="auto"
                                  return-object
                                  :loading="loadingExecutorAction[index]"
                                  @change="changeExecutorAction(index)"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider class="mb-2" />
                          <component
                            :is="executor.action.component_name"
                            v-if="
                              (!isEdit && executor.action) ||
                              (isEdit && executor.action && executor.props)
                            "
                            :key="formKey"
                            ref="executorForms"
                            v-model="executor.props"
                            :props="executor.props"
                            :token="executor.token"
                            :options="triggerPropertyList"
                            :index="index"
                            class="pa-3"
                            @changeToken="changeToken"
                          />
                          <div
                            v-if="executor.action"
                            class="mt-2"
                          />
                        </v-card>
                      </XyzTransition>
                    </v-card>
                  </XyzTransitionGroup> -->
                </div>
              </div>
            </v-card>
          </v-col>

          <!-- <v-col
            cols="12"
            md="3"
            class="pl-md-2"
          >
            <span class="text-subtitle-2">Ringkasan</span>
            <XyzTransitionGroup
              appear
              xyz="fade right-2 duration-4"
              class="summary-action"
            >
              <v-card
                v-for="(summary, index) in workflowSummary"
                :key="index"
                class="pa-2 mt-2 d-flex align-center"
                outlined
              >
                <v-chip
                  class="mr-2 px-2 font-medium"
                  x-small
                >
                  {{ index + 1 }}
                </v-chip>
                <v-icon
                  v-if="summary.type === 'trigger'"
                  size="20"
                >
                  {{ icons.mdiContainStart }}
                </v-icon>
                <v-icon
                  v-if="summary.type === 'filter'"
                  size="20"
                >
                  {{ icons.mdiFilterOutline }}
                </v-icon>
                <v-icon
                  v-if="summary.type === 'action'"
                  size="20"
                >
                  {{ icons.mdiContainEnd }}
                </v-icon>
                <span class="text-caption primary--text ms-1">{{ summary.name }}</span>
              </v-card>
            </XyzTransitionGroup>
          </v-col> -->
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { useRouter } from '@/@core/utils'
import {
  mdiClose,
  mdiContainEnd,
  mdiContainStart,
  mdiFilterOutline,
  mdiPlus,
  mdiSourceCommit,
  mdiTuneVertical,
} from '@mdi/js'
import { ref } from 'vue'

// ! TEST
import WorkflowFieldInfo from './components/WorkflowFieldInfo.vue'
// import WorkflowFilterField from './WorkflowFilterField.vue'

// import WorkflowFilterOptions from './components/WorkflowFilterOptions.vue'

// import WorkflowCreateTaskForm from './forms/task/WorkflowCreateTaskForm.vue'
// import WorkflowUpdateTaskForm from './forms/task/WorkflowUpdateTaskForm.vue'
// import WorkflowDeleteTaskForm from './forms/task/WorkflowDeleteTaskForm.vue'

// import WorkflowCreateJobForm from './forms/job/WorkflowCreateJobForm.vue'
// import WorkflowUpdateJobForm from './forms/job/WorkflowUpdateJobForm.vue'
// import WorkflowDeleteJobForm from './forms/job/WorkflowDeleteJobForm.vue'

// import WorkflowCreateJobForm from './forms/job/WorkflowCreateJobForm.vue'
// import WorkflowUpdateJobForm from './forms/job/WorkflowUpdateJobForm.vue'
// import WorkflowDeleteJobForm from './forms/job/WorkflowDeleteJobForm.vue'

// import WorkflowCreateProductForm from './forms/product/WorkflowCreateProductForm.vue'
// import WorkflowUpdateProductForm from './forms/product/WorkflowUpdateProductForm.vue'
// import WorkflowDeleteProductForm from './forms/product/WorkflowDeleteProductForm.vue'

export default {
  components: {
    WorkflowFieldInfo,
    // WorkflowFilterField,
    // WorkflowFilterOptions,
    // // TaskForm,
    // WorkflowCreateTaskForm,
    // WorkflowUpdateTaskForm,
    // WorkflowDeleteTaskForm,

    // WorkflowCreateJobForm,
    // WorkflowUpdateJobForm,
    // WorkflowDeleteJobForm,

    // WorkflowCreateProductForm,
    // WorkflowUpdateProductForm,
    // WorkflowDeleteProductForm,
  },

  props: {
    folder: {
      type: Object,
      required: true,
    },
    jobType: {
      type: Object,
    },
  },

  setup() {
    const { router } = useRouter()

    const automationDetail = {
      id: undefined,
      name: undefined,
      description: undefined,
      is_active: undefined,
      is_automation_locked: undefined,
      group_operator: undefined,
    }
    const triggerList = ref([
      {
        id: undefined,
        name: undefined,
        is_active: undefined,
        property: undefined,
        from_operator: undefined,
        to_operator: undefined,
        from_value: undefined,
        to_value: undefined,
      },
    ])

    return {
      automationDetail,
      triggerList,

      router,

      icons: {
        mdiClose,
        mdiPlus,
        mdiTuneVertical,
        mdiContainStart,
        mdiFilterOutline,
        mdiContainEnd,
        mdiSourceCommit,
      },
    }
  },
}
</script>
