<template>
  <v-card
    class="pt-0 pb-5 px-5 d-flex flex-column flex-fill overflow-auto"
    id="user-view"
    style="max-height: 100%"
  >
    <v-row
      :class="`${
        (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
          ? 'overflow-hidden'
          : ''
      }`"
      dense
      no-gutters
      align-content="start"
    >
      <v-col
        cols="12"
        :lg="isFullview ? 3 : 12"
        :style="{
          height:
            (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
              ? '100%'
              : 'unset',
          maxHeight: '100%',
        }"
      >
        <div
          class="d-flex flex-column overflow-hidden h-full"
          style="max-height: 100%"
        >
          <v-card
            class="pa-4 mb-4 d-flex flex-column align-center justify-center"
            outlined
          >
            <v-avatar
              size="80"
              class="v-avatar-light-bg mb-2"
            >
              {{ avatarText(`${detail.first_name} ${detail.last_name}`) }}
            </v-avatar>

            <span class="text-subtitle-2 font-weight-bold primary--text text-center">
              {{ detail.first_name }} {{ detail.last_name }}
            </span>
            <span class="text-subtitle-1 text-center">{{ detail.email }}</span>
            <span class="text-subtitle-2 text-center">{{ detail.phone }}</span>

            <div class="d-flex mt-2 mb-4">
              <v-chip
                small
                color="primary"
                class="mx-1 v-chip-light-bg primary--text"
              >
                {{ detail.role.name }}
              </v-chip>
              <v-chip
                small
                class="mx-1"
                :color="userStatusColor(detail.user_status.id)"
              >
                {{ detail.user_status.name }}
              </v-chip>
            </div>

            <v-card
              outlined
              class="pa-3 d-flex justify-space-between w-100 align-center"
            >
              <span class="text-subtitle-2">Ketersediaan</span>
              <v-switch
                :value="detail.availability"
                disabled
                hide-details
                dense
                class="ma-0"
              />
            </v-card>
          </v-card>

          <v-card
            :class="`d-flex py-2 px-4 ${
              (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
                ? 'overflow-hidden h-full'
                : 'mb-4'
            }`"
            outlined
          >
            <template v-if="teamsResultList.length === 0">
              <v-alert
                class="mt-4"
                elevation="2"
                width="95%"
              >
                Pengguna tidak bergabung ke dalam tim.
              </v-alert>
            </template>

            <template v-else>
              <v-expansion-panels
                :value="isFullview ? 0 : undefined"
                :readonly="isFullview"
              >
                <v-expansion-panel
                  outlined
                  key="0"
                  class="custom-panel v-card v-sheet--outlined"
                  style="display: flex; overflow: hidden; max-height: 100%; flex-direction: column"
                >
                  <v-expansion-panel-header class="py-0 px-2">
                    <span class="text-subtitle-1 font-weight-medium">Tim</span>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content
                    class="custom-panel-content overflow-auto mb-2"
                    :style="{ maxHeight: isFullview ? '100%' : '420px' }"
                  >
                    <v-row
                      no-gutters
                      class="mb-3"
                      v-for="(team, index) in teamsResultList"
                      :key="index"
                    >
                      <v-card
                        v-if="!successLeaveTeam.includes(team.id)"
                        outlined
                        :key="team.id"
                        class="pa-2 d-flex flex-fill"
                      >
                        <v-row no-gutters>
                          <v-col cols="12">
                            <v-btn
                              class="no-hover"
                              :plain="true"
                              :ripple="false"
                              :x-small="true"
                              :hover="false"
                              @click.stop="
                                $router.push({
                                  name: 'team-detail',
                                  params: {
                                    id: team.id,
                                  },
                                })
                              "
                            >
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    class="text-subtitle-2 primary--text pa-0 ma-0 text-truncate d-inline-block"
                                    style="max-width: 120px"
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    {{ team.name }}
                                    <v-icon
                                      v-if="team.archived_at"
                                      size="18"
                                      class="ms-1"
                                    >
                                      {{ icons.mdiArchive }}
                                    </v-icon>
                                  </span>
                                </template>
                                <span>{{ team.name }}</span>
                              </v-tooltip>
                            </v-btn>
                            <v-row>
                              <span class="font-size-role ml-6 mt-2 mb-5">
                                {{
                                  team.users.length > 0 &&
                                  team.users.filter(tu => tu.user.id == idUser)[0].permission_type
                                }}
                              </span>
                            </v-row>
                          </v-col>

                          <v-col cols="5">
                            <div
                              v-if="isJoinedTeam(team) && currentUserId === idUser"
                              class="mt-3"
                            >
                              <v-btn
                                v-if="team.is_public"
                                class="items-center"
                                style="min-width: 80px"
                                x-small
                                variant="outlined"
                                color="error"
                                @click="
                                  handleLeaveTeam({
                                    team_id: team.id,
                                  })
                                "
                                :loading="isLoadingTeam.includes(team.id)"
                                v-show="!successLeaveTeam.includes(team.id)"
                              >
                                Leave Team
                              </v-btn>
                              <v-btn
                                v-else
                                class="items-center"
                                x-small
                                style="min-width: 80px"
                                variant="outlined"
                                color="error"
                                @click="
                                  handleLeaveTeam({
                                    team_id: team.id,
                                  })
                                "
                                :loading="isLoadingTeam.includes(team.id)"
                                v-show="!successLeaveTeam.includes(team.id)"
                              >
                                Leave Team
                              </v-btn>
                            </div>
                            <div v-if="!isJoinedTeam(team) || successLeaveTeam.includes(team.id)">
                              <v-btn
                                v-if="team.is_public && !successJoinTeam.includes(team.id)"
                                class="items-center mt-3"
                                style="min-width: 80px"
                                x-small
                                color="primary"
                                @click="
                                  handleJoinTeam({
                                    id: team.id,
                                    isPublic: team.is_public,
                                  })
                                "
                                :loading="isLoadingTeam.includes(team.id)"
                              >
                                Join
                              </v-btn>
                              <v-btn
                                v-else-if="!successRequestJoinTeam.includes(team.id)"
                                class="items-center mt-3"
                                x-small
                                style="min-width: 80px"
                                color="primary"
                                @click="
                                  handleJoinTeam({
                                    id: team.id,
                                    isPublic: team.is_public,
                                  })
                                "
                                :loading="isLoadingTeam.includes(team.id)"
                              >
                                Request Join
                              </v-btn>
                              <v-btn
                                v-else-if="
                                  successRequestJoinTeam.includes(team.id) || isRequestedTeam(team)
                                "
                                class="items-center mt-3"
                                x-small
                                style="min-width: 80px"
                                color="primary"
                                @click="
                                  handleJoinTeam({
                                    id: team.id,
                                    isPublic: team.is_public,
                                  })
                                "
                                :loading="isLoadingTeam.includes(team.id)"
                              >
                                Re-request Join
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-card>
        </div>
      </v-col>

      <v-col
        class="w-full"
        cols="12"
        :lg="isFullview ? 9 : 12"
        :style="{
          height:
            (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
              ? '100%'
              : 'unset',
          maxHeight:
            (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
              ? '100%'
              : '500px',
        }"
      >
        <v-card
          :class="`pa-4 h-full d-flex flex-column flex-fill overflow-hidden ${
            (isFullview && $vuetify.breakpoint.lg) || (isFullview && $vuetify.breakpoint.xl)
              ? 'ml-4'
              : ''
          }`"
          outlined
        >
          <v-tabs
            v-model="currentTab"
            class="user-tabs mb-3"
            style="flex: 0"
            show-arrows
            centered
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.label"
              class="w-full"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span class="text-capitalize text-subtitle-2">{{ tab.label }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items
            class="flex-fill h-full overflow-hidden px-2"
            id="user-tabs-content"
            v-model="currentTab"
            touchless
          >
            <v-tab-item class="h-full">
              <UserDetailReferenceLog />

              <!-- <user-reference-log :id="idUser" /> -->
            </v-tab-item>

            <v-tab-item class="h-full">
              <UserDetailTrackJob />

              <!-- <user-job :id="idUser" /> -->
            </v-tab-item>

            <v-tab-item class="h-full">
              <UserDetailAssignedComment />

              <!-- <user-comment :id="idUser" /> -->
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <user-target-form ref="userTargetForm" />
  </v-card>
</template>

<script>
import { can } from '@/@core/libs/acl/utils'
import useTeam from '@/composables/useTeam'
import useUser from '@/composables/useUser'
import { leaveTeam } from '@/graphql/mutations'
import { teams } from '@/graphql/queries'
import router from '@/router'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { avatarText } from '@core/utils/filter'
import {
  mdiArchive,
  mdiComment,
  mdiInformation,
  mdiLibrary,
  mdiOpenInNew,
  mdiTicketAccount,
} from '@mdi/js'
import Vue, { computed, onMounted, provide, ref, watch } from 'vue'
// import UserComment from './components/UserComment.vue'
// import UserJob from './components/UserJob.vue'
// import UserReferenceLog from './components/UserReferenceLog.vue'
import UserTargetForm from './UserTargetForm.vue'
import UserDetailAssignedComment from './components/UserDetailAssignedComment.vue'
import UserDetailReferenceLog from './components/UserDetailReferenceLog.vue'
import UserDetailTrackJob from './components/UserDetailTrackJob.vue'
import useTimeline from '@/composables/useTimeline'
import useJob from '@/composables/useJob'
import useComment from '@/composables/useComment'

export default {
  components: {
    UserTargetForm,
    // UserPerformance,
    // UserReferenceLog,
    // UserActivity,
    // UserJob,
    // UserComment,
    // UserTask,

    UserDetailReferenceLog,
    UserDetailTrackJob,
    UserDetailAssignedComment,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
    isFullview: {
      type: Boolean,
    },
  },
  setup(props) {
    const idUser = computed(() => Number(props.id))
    const TEAM_PREFIX = 't'
    const isTeam = item => item.key.startsWith(TEAM_PREFIX)
    const isJoinedObject = item => item.status_permission !== 0
    const isJoinedTeam = item => item.status === 'joined'
    const isRequestedTeam = item => item.status === 'requested'
    const ownerId = ref()
    const { joinTeam, requestJoin } = useTeam()
    // const loadingTeam = ref([])
    const successJoinTeam = ref([])
    const successRequestJoinTeam = ref([])
    const successLeaveTeam = ref([])
    const isLoadingTeam = ref([])
    const loadingSkeleton = ref(true)
    const currentUserId = ref(store.getters.getUserData.id)
    const showToast = (title, text, icon, type = 'error') => {
      Vue.notify({
        title,
        type,
        icon,
        text,
      })
    }
    const leaveTeamMutate = ({ team_id }) => {
      apolloClient
        .mutate({
          mutation: leaveTeam,
          variables: {
            team_id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(() => {
          showToast('Berhasil', 'Anda telah berhasil meninggalkan Tim', 'success')
          successLeaveTeam.value.push(team_id)
          const index = isLoadingTeam.value.indexOf(team_id)
          if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
          const index2 = successJoinTeam.value.indexOf(team_id)
          if (index2 != undefined && index2 >= 0) successJoinTeam.value.splice(index2, 1)
          const index3 = successRequestJoinTeam.value.indexOf(team_id)
          if (index3 != undefined && index3 >= 0) successRequestJoinTeam.value.splice(index3, 1)
        })
        .catch(err => {
          errorHandling(err)
          const index = isLoadingTeam.value.indexOf(team_id)
          if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
        })
    }

    const handleLeaveTeam = ({ team_id }) => {
      Vue.$dialog({
        title: 'Keluar dari tim?',
        body: 'Konfirmasi jika anda ingin keluar dari tim ini.',
      }).then(async confirm => {
        if (confirm) {
          isLoadingTeam.value.push(team_id)
          try {
            await leaveTeamMutate({ team_id })
            await store.dispatch('team/fetchTeams')
          } catch (err) {
            //
          }
        }
      })
    }
    const handleJoinTeam = async ({ id, isPublic }) => {
      isLoadingTeam.value.push(id)
      if (isPublic) {
        await joinTeam(id)
          .then(() => {
            const index = isLoadingTeam.value.indexOf(id)
            if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
            const index2 = successLeaveTeam.value.indexOf(id)
            if (index2 != undefined && index2 >= 0) successLeaveTeam.value.splice(index2, 1)
            successJoinTeam.value.push(id)
            showToast('Berhasil', 'Anda telah berhasil bergabung dalam Tim', 'success')
          })
          .catch(err => {
            errorHandling(err)
            const index = isLoadingTeam.value.indexOf(id)
            if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
          })
      } else {
        await requestJoin(id)
          .then(() => {
            const index = isLoadingTeam.value.indexOf(id)
            if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
            successRequestJoinTeam.value.push(id)
            showToast('Berhasil', 'Request Terkirim', 'icon: mdiLibrary', 'success')
          })
          .catch(err => {
            errorHandling(err)
            const index = isLoadingTeam.value.indexOf(id)
            if (index != undefined && index >= 0) isLoadingTeam.value.splice(index, 1)
          })
      }
    }

    const getRoleInTeam = async team => {
      const index = team.users.findIndex(el => el.user.id === idUser.value)

      return team.users[index].permission_type
    }

    const loadingDetail = ref(false)
    const detail = ref({})
    const teamsResultList = ref([])
    const teamsResultCount = ref(0)
    const handleInvite = () => console.log('invite to object')
    const currentTab = ref(0)
    const perfectScrollbarOptions = {
      maxScrollbarLength: 200,
      wheelPropagation: false,
    }
    const tabs = ref([
      {
        label: 'Aktivitas',
        icon: mdiLibrary,
      },
      {
        label: 'Job',
        icon: mdiTicketAccount,
      },
      {
        label: 'Comment',
        icon: mdiComment,
      },
    ])

    const findUser = () => {
      loadingDetail.value = true

      const user = store.state.workspace.selectedWorkspace.workspace.members.find(
        el => el.user.id === Number(props.id),
      )

      if (user) {
        detail.value = {
          ...user.user,
          role: user.role,
        }
      } else {
        router.push('/error')
        errorHandling('Pengguna tidak ditemukan')
      }

      loadingDetail.value = false
    }

    const fetchTeams = () =>
      new Promise((resolve, reject) => {
        loadingSkeleton.value = true

        apolloClient
          .query({
            query: teams,
            fetchPolicy: 'no-cache',
            variables: {
              workspace_id: store.getters.getCurrentWorkspaceId,
              userId: [Number(props.id)],
            },
          })
          .then(({ data }) => {
            let teamsList = data.teams.teams
            const teamsCount = data.teams.count
            loadingSkeleton.value = false

            let newData = []

            teamsList = teamsList.map(val => {
              newData = val

              return newData
            })

            resolve({ teamsList, teamsCount })
          })
          .catch(err => {
            loadingSkeleton.value = false
            errorHandling(err)
            reject(err)
          })
      })

    onMounted(() => {
      if (can('read', 'User')) {
        findUser()
      } else {
        router.push('/error')
        errorHandling('Anda tidak memiliki akses')
      }
      fetchTeams()
        .then(({ teamsList, teamsCount }) => {
          teamsResultList.value = teamsList
          teamsResultCount.value = teamsCount
        })
        .catch(err => {
          // Handle errors here
          console.error(err)
        })
    })

    const { userStatusColor } = useUser()

    // --------------------------------- @dev-deeka --------------------------------- //
    const pagination = ref({
      limit: 50,
      offset: 0,
    })
    const referenceLogsList = ref({})
    const jobsList = ref([])
    const commentsList = ref([])
    const { fetchReferenceLogsByUser } = useTimeline()
    const { fetchJobsAssignedUser } = useJob()
    const { fetchAssignedComments } = useComment()

    const handleDataFetch = async () => {
      fetchReferenceLogsByUser(pagination.value, idUser.value).then(data => {
        data.referenceLogs.map((item, index) => {
          if (index > 0 && item.job.id === data.referenceLogs[index - 1].job.id)
            return referenceLogsList.value[item.job.id].logList.push(item)

          referenceLogsList.value = {
            ...referenceLogsList.value,
            [item.job.id]: {
              id: item.id,
              job: item.job,

              logList: [item],
            },
          }
        })
      })

      for (const tabFor of [
        { target: 'to_day', title: 'Hari Ini' },
        { target: 'late', title: 'Terlambat' },
        { target: 'next', title: 'Selanjutnya' },
        { target: 'assigned', title: 'Ditugaskan' },
        { target: 'done', title: 'Terselesaikan' },
      ]) {
        try {
          const data = await fetchJobsAssignedUser(pagination.value, idUser.value, tabFor.target)
          jobsList.value.push({
            title: tabFor.title,
            count: data.count,
            jobs: data.jobs,
          })
        } catch (error) {
          console.error(`Error fetching data for ${tabFor}:`, error)
        }
      }

      for (const tabFor of [
        { target: 'job', resolve: 'FALSE' },
        { target: 'doc', resolve: 'FALSE' },
        { target: 'job', resolve: 'TRUE' },
        { target: 'doc', resolve: 'TRUE' },
      ]) {
        try {
          const data = await fetchAssignedComments(
            pagination.value,
            idUser.value,
            tabFor.resolve,
            tabFor.target,
          )
          commentsList.value.push({
            title: tabFor.target,
            count: data.count,
            comments: data.comments,
          })
        } catch (error) {
          console.error(`Error fetching data for ${tabFor}:`, error)
        }
      }
    }

    provide('isFullview', props.isFullview)
    provide('referenceLogsList', referenceLogsList)
    provide('jobsList', jobsList)
    provide('commentsList', commentsList)
    provide('isFullview', props.isFullview)

    watch(idUser, () => {
      findUser()

      handleDataFetch()

      fetchTeams()
        .then(({ teamsList, teamsCount }) => {
          teamsResultList.value = teamsList
          teamsResultCount.value = teamsCount
        })
        .catch(err => {
          console.error(err)
        })
    })

    onMounted(() => {
      handleDataFetch()
    })

    return {
      fetchTeams,
      loadingDetail,
      detail,
      tabs,
      avatarText,
      currentTab,
      userStatusColor,
      icons: {
        mdiInformation,
        mdiOpenInNew,
        mdiComment,
        mdiArchive,
      },
      idUser,
      teamsResultList,
      teamsResultCount,
      handleJoinTeam,
      isJoinedTeam,
      isRequestedTeam,
      isTeam,
      // loadingTeam,
      successJoinTeam,
      successRequestJoinTeam,
      isJoinedObject,
      ownerId,
      handleInvite,
      isLoadingTeam,
      perfectScrollbarOptions,
      currentUserId,
      showToast,
      handleLeaveTeam,
      leaveTeam,
      leaveTeamMutate,
      successLeaveTeam,
      loadingSkeleton,
      getRoleInTeam,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.scroll-timeline {
  @include style-scroll-bar();
  overflow-y: scroll;
  overflow-x: hidden;
}

.ps-team-list {
  max-height: calc(var(--vh, 1vh) * 60);
}

.font-size-role {
  font-size: 13px;
}
</style>
