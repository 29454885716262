<template>
  <div class="d-flex flex-column overflow-hidden h-full">
    <div>
      <span class="caption ml-1 mb-1">{{ t('Tampilkan berdasarkan') }}</span>
    </div>

    <div class="d-flex mb-4">
      <v-chip
        class="d-flex flex-column align-stretch flex-fill mr-2 pl-3 pr-1 overflow-hidden"
        style="border-radius: 6px; height: 38px"
        :color="filterUnread ? 'primary' : ''"
        @click="filterUnread = !filterUnread"
      >
        <span class="text-subtitle-2 text-truncate">
          {{ filterUnread ? t('Hanya yang telah ter-Resolve') : t('Hanya yang belum ter-Resolve') }}
        </span>

        <v-icon
          v-if="filterUnread"
          class="ml-auto mr-2"
          size="20"
          style="color: white !important"
        >
          {{ icons.mdiCheck }}
        </v-icon>
      </v-chip>

      <v-tooltip bottom>
        <template #activator="tooltip">
          <v-menu
            bottom
            center
            offset-y
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="v-card v-sheet--outlined px-4"
                depressed
                outlined
                style="border-radius: 6px"
                v-bind="{ ...tooltip.attrs, ...attrs }"
                v-on="{ ...tooltip.on, ...on }"
              >
                <v-icon size="20">
                  {{ icons.mdiFilterVariant }}
                </v-icon>

                <span class="text-subtitle-1 font-weight-medium pl-2">Sort</span>

                <v-badge
                  class="pl-1"
                  inline
                  v-show="filterCategory.id"
                  :value="filterCategory.id"
                  :content="filterCategory.id ? '1' : undefined"
                >
                </v-badge>
              </v-btn>
            </template>

            <v-card
              class="d-flex flex-column px-3 pt-2 pb-3"
              width="320px"
            >
              <v-row
                no-gutters
                dense
                class="mb-1"
              >
                <v-col>
                  <span class="body-2">{{ t('Filter berdasarkan') }}</span>
                </v-col>

                <v-col
                  class="py-0 px-0 d-flex justify-end"
                  cols="4"
                >
                  <v-btn
                    text
                    class="px-4"
                    color="primary"
                    style="height: unset !important; min-width: unset !important"
                    @click="handleResetFilter"
                  >
                    Reset
                  </v-btn>
                </v-col>
              </v-row>

              <v-row
                class="mt-2"
                no-gutters
                dense
              >
                <v-autocomplete
                  v-model="filterCategory"
                  solo
                  flat
                  dense
                  outlined
                  return-object
                  item-value="id"
                  item-text="label"
                  auto-select-first
                  hide-details="auto"
                  :items="filterList"
                  :placeholder="t('Berdasarkan ketegori')"
                >
                  <template #selection="{ item }">
                    <v-icon
                      class="mr-2"
                      size="20"
                    >
                      {{ item.icon }}
                    </v-icon>

                    {{ item.label }}
                  </template>

                  <template #item="{ item }">
                    <div class="d-flex align-center flex-grow-1 py-3">
                      <v-icon
                        class="mr-2"
                        size="20"
                      >
                        {{ item.icon }}
                      </v-icon>
                      <span class="subtitle-2"> {{ item.label }}</span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-row>
            </v-card>
          </v-menu>
        </template>

        <span>{{ t('Filter notifikasi') }}</span>
      </v-tooltip>
    </div>

    <div
      class="d-flex flex-column h-full overflow-auto px-1"
      style="max-height: 100%"
    >
      <template v-if="!filterUnread">
        <v-card
          v-for="(list, index) in commentsList.slice(0, 2)"
          :key="index"
          class="d-flex mb-2"
          outlined
        >
          <v-expansion-panels>
            <v-expansion-panel
              outlined
              class="custom-panel v-card v-sheet--outlined"
            >
              <v-expansion-panel-header
                class="py-2 px-4"
                style="min-height: unset !important"
              >
                <span class="text-subtitle-2 font-weight-semibold">
                  {{ list.title === 'job' ? 'Job' : 'Document' }}&nbsp;
                  <span style="opacity: 0.6">
                    {{ list.count }}
                  </span>
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                class="custom-panel-content overflow-auto mt-2 mb-2 px-2"
                style="max-height: 420px"
              >
                <v-card
                  v-for="(comment, index) in list.comments"
                  :key="index"
                  class="d-flex flex-column px-4 py-3 mb-2 overflow-hidden"
                  outlined
                  style="max-width: 100%"
                >
                  <span
                    class="d-flex align-center mt-1 mb-2 text-subtitle-2 text-no-wrap font-weight-semibold overflow-hidden"
                    style="max-width: 100%"
                  >
                    <template v-if="comment.job">
                      <v-hover v-slot="{ hover }">
                        <span
                          :class="`${hover ? 'text-decoration-underline' : ''}`"
                          :style="{
                            transition: 'opacity 200ms linear',
                            cursor: 'pointer',
                          }"
                          @click="
                            $router.push({
                              name: 'folder-detail',
                              params: { id: comment.job.job_type.folder.id },
                            })
                          "
                          @click.stop
                        >
                          <v-icon
                            class="mb-1 mr-1"
                            size="20"
                            :color="comment.job.job_type.folder.color"
                          >
                            {{ icons.mdiFolder }} </v-icon
                          >{{ comment.job.job_type.folder.name }}
                        </span>
                      </v-hover>
                      <span style="opacity: 0.6">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                      <v-hover v-slot="{ hover }">
                        <span
                          :class="`text-truncate${hover ? ' text-decoration-underline' : ''}`"
                          :style="{
                            transition: 'opacity 200ms linear',
                            cursor: 'pointer',
                          }"
                          @click="
                            $router.push({
                              name: 'jobtype-detail',
                              params: {
                                id: comment.job.job_type.folder.id,
                                jobtypeid: comment.job.job_type.id,
                              },
                            })
                          "
                          @click.stop
                        >
                          <v-icon
                            class="mr-1"
                            size="10"
                            :color="comment.job.job_type.color"
                            style="margin-bottom: 2.5px"
                          >
                            {{ icons.mdiCircle }} </v-icon
                          >{{ comment.job.job_type.name }}
                        </span>
                      </v-hover>
                    </template>

                    <template v-if="comment.document">
                      <v-hover v-slot="{ hover }">
                        <span
                          :class="`${hover ? 'text-decoration-underline' : ''}`"
                          :style="{
                            transition: 'opacity 200ms linear',
                            cursor: 'pointer',
                          }"
                          @click="
                            $router.push({
                              name: 'folder-detail',
                              params: { id: comment.document.uuid },
                            })
                          "
                          @click.stop
                        >
                          <v-icon
                            class="mb-1 mr-1"
                            size="20"
                          >
                            {{ icons.mdiFileDocumentOutline }} </v-icon
                          >{{ comment.document.name }}
                        </span>
                      </v-hover>
                    </template>
                  </span>

                  <NotificationItemComment
                    class="mx-1"
                    :hide-details="!isFullview || $vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                    :comment="comment"
                    :job="comment?.job"
                    :attachment="comment?.attachment"
                    :document="comment?.document"
                  ></NotificationItemComment>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </template>

      <template v-else>
        <v-card
          v-for="(list, index) in commentsList.slice(2, 4)"
          :key="index"
          class="d-flex mb-2"
          outlined
        >
          <v-expansion-panels>
            <v-expansion-panel
              outlined
              class="custom-panel v-card v-sheet--outlined"
            >
              <v-expansion-panel-header
                class="py-2 px-4"
                style="min-height: unset !important"
              >
                <span class="text-subtitle-2 font-weight-semibold">
                  {{ list.title === 'job' ? 'Job' : 'Document' }}&nbsp;
                  <span style="opacity: 0.6">
                    {{ list.count }}
                  </span>
                </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                class="custom-panel-content overflow-auto mt-2 mb-2 px-2"
                style="max-height: 420px"
              >
                <v-card
                  v-for="(comment, index) in list.comments"
                  :key="index"
                  class="d-flex flex-column px-4 py-3 mb-2 overflow-hidden"
                  outlined
                  style="max-width: 100%"
                >
                  <span
                    class="d-flex align-center mt-1 mb-2 text-subtitle-2 text-no-wrap font-weight-semibold overflow-hidden"
                    style="max-width: 100%"
                  >
                    <template v-if="comment.job">
                      <v-hover v-slot="{ hover }">
                        <span
                          :class="`${hover ? 'text-decoration-underline' : ''}`"
                          :style="{
                            transition: 'opacity 200ms linear',
                            cursor: 'pointer',
                          }"
                          @click="
                            $router.push({
                              name: 'folder-detail',
                              params: { id: comment.job.job_type.folder.id },
                            })
                          "
                          @click.stop
                        >
                          <v-icon
                            class="mb-1 mr-1"
                            size="20"
                            :color="comment.job.job_type.folder.color"
                          >
                            {{ icons.mdiFolder }} </v-icon
                          >{{ comment.job.job_type.folder.name }}
                        </span>
                      </v-hover>
                      <span style="opacity: 0.6">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                      <v-hover v-slot="{ hover }">
                        <span
                          :class="`text-truncate${hover ? ' text-decoration-underline' : ''}`"
                          :style="{
                            transition: 'opacity 200ms linear',
                            cursor: 'pointer',
                          }"
                          @click="
                            $router.push({
                              name: 'jobtype-detail',
                              params: {
                                id: comment.job.job_type.folder.id,
                                jobtypeid: comment.job.job_type.id,
                              },
                            })
                          "
                          @click.stop
                        >
                          <v-icon
                            class="mr-1"
                            size="10"
                            :color="comment.job.job_type.color"
                            style="margin-bottom: 2.5px"
                          >
                            {{ icons.mdiCircle }} </v-icon
                          >{{ comment.job.job_type.name }}
                        </span>
                      </v-hover>
                    </template>

                    <template v-if="comment.document">
                      <v-hover v-slot="{ hover }">
                        <span
                          :class="`${hover ? 'text-decoration-underline' : ''}`"
                          :style="{
                            transition: 'opacity 200ms linear',
                            cursor: 'pointer',
                          }"
                          @click="
                            $router.push({
                              name: 'folder-detail',
                              params: { id: comment.document.uuid },
                            })
                          "
                          @click.stop
                        >
                          <v-icon
                            class="mb-1 mr-1"
                            size="20"
                          >
                            {{ icons.mdiFileDocumentOutline }} </v-icon
                          >{{ comment.document.name }}
                        </span>
                      </v-hover>
                    </template>
                  </span>

                  <NotificationItemComment
                    class="mx-1"
                    :comment="comment"
                    :job="comment?.job"
                    :attachment="comment?.attachment"
                    :document="comment?.document"
                  ></NotificationItemComment>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>
import { categoryMap, filterList } from '@/constants/notification'
import NotificationItemComment from '@/views/notification/components/NotificationItemComment.vue'
import { useUtils } from '@core/libs/i18n'
import {
  mdiCheck,
  mdiFilterVariant,
  mdiFolder,
  mdiCircle,
  mdiOpenInNew,
  mdiFileDocumentOutline,
} from '@mdi/js'
import { inject, ref } from 'vue'

export default {
  components: {
    NotificationItemComment,
  },

  setup() {
    const commentsList = inject('commentsList')
    const isFullview = inject('isFullview')
    const filterUnread = ref(false)
    const filterCategory = ref({
      id: null,
      label: 'Pilih kategori',
      icon: null,
      message: null,
    })

    const { t } = useUtils()

    const handleResetFilter = () => {
      filterUnread.value = false
      filterCategory.value = {
        id: null,
        label: 'Pilih kategori',
        icon: null,
        message: null,
      }
    }

    return {
      // i18n
      t,

      commentsList,
      isFullview,
      filterUnread,
      filterCategory,

      handleResetFilter,

      // constant
      categoryMap,
      filterList,

      icons: {
        mdiFilterVariant,
        mdiCheck,
        mdiFolder,
        mdiCircle,
        mdiOpenInNew,
        mdiFileDocumentOutline,
      },
    }
  },
}
</script>
