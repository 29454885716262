<template>
  <div class="d-flex flex-column overflow-hidden h-full">
    <div>
      <span class="caption ml-1 mb-1">{{ t('Tampilkan berdasarkan') }}</span>
    </div>

    <div class="d-flex mb-4">
      <v-text-field
        v-model="filterByName"
        class="user-detail-activity-search mr-2"
        solo
        flat
        dense
        outlined
        hide-details="auto"
        placeholder="Cari Job dengan Nama"
      >
      </v-text-field>

      <v-tooltip bottom>
        <template #activator="tooltip">
          <v-menu
            v-model="filterCategoryMenu"
            :close-on-content-click="false"
            left
            nudge-left="20"
            transition="slide-y-reverse-transition"
          >
            <template #activator="menu">
              <v-btn
                class="v-card v-sheet--outlined px-4"
                depressed
                outlined
                style="border-radius: 6px"
                v-bind="{ ...tooltip.attrs, ...menu.attrs }"
                v-on="{ ...tooltip.on, ...menu.on }"
              >
                <v-icon size="20">
                  {{ icons.mdiFilter }}
                </v-icon>

                <span class="text-subtitle-1 font-weight-medium pl-2">Filter</span>

                <v-badge
                  class="pl-1"
                  inline
                  v-show="isFilterExist"
                  :value="isFilterExist"
                >
                </v-badge>
              </v-btn>
            </template>

            <v-card min-width="350px">
              <v-card-text>
                <v-text-field
                  v-model="filterByName"
                  placeholder="Cari Kategori"
                  dense
                  hide-details
                />

                <div class="v-list--dense d-flex justify-space-between align-center">
                  <v-subheader class="px-0"> Kategori </v-subheader>

                  <v-btn
                    text
                    x-small
                    color="primary"
                    @click="handleSelectCategory"
                  >
                    {{ filterCategory.length === 20 ? 'Batal Semua Pilihan' : 'Pilih Semua' }}
                  </v-btn>
                </div>

                <v-list
                  bottom
                  dense
                  max-height="32vh"
                  min-width="250px"
                  style="overflow-y: auto"
                  class="pt-0 reference-log-category"
                >
                  <template v-for="category in searchedCategory">
                    <v-list-item
                      :key="category.id"
                      class="px-0"
                    >
                      <v-switch
                        v-model="filterCategory"
                        :label="category.label"
                        inset
                        dense
                        hide-details
                        color="primary"
                        :value="category.id"
                        class="mt-0 text-no-wrap ps-2"
                        @change="handleFetchParentData"
                      >
                        <template #label>
                          <v-icon
                            left
                            size="18"
                          >
                            {{ category.icon }}
                          </v-icon>
                          <span>{{ category.label }}</span>
                        </template>
                      </v-switch>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-menu>
        </template>

        <span>{{ t('Filter notifikasi') }}</span>
      </v-tooltip>
    </div>

    <div
      class="d-flex flex-column h-full overflow-auto px-1"
      style="max-height: 100%"
    >
      <v-card
        v-for="(referenceLog, index) in referenceLogsList"
        :key="index"
        class="d-flex mb-2"
        outlined
      >
        <v-expansion-panels multiple>
          <v-expansion-panel
            outlined
            class="custom-panel v-card v-sheet--outlined"
          >
            <v-expansion-panel-header
              class="py-2 px-4"
              style="min-height: unset !important"
            >
              <div
                class="d-flex flex-column align-start pt-2 pb-1 overflow-hidden"
                style="max-width: 100%"
              >
                <span
                  class="d-flex text-subtitle-2 mb-1 text-no-wrap font-weight-semibold overflow-hidden"
                  style="max-width: 100%"
                >
                  <v-hover v-slot="{ hover }">
                    <span
                      :class="`${hover ? 'text-decoration-underline' : ''}`"
                      :style="{ transition: 'opacity 200ms linear', opacity: hover ? 1 : 0.6 }"
                      @click="
                        $router.push({
                          name: 'folder-detail',
                          params: { id: referenceLog.job.job_type.folder.id },
                        })
                      "
                      @click.stop
                    >
                      <v-icon
                        class="mb-1 mr-1"
                        size="20"
                        :color="referenceLog.job.job_type.folder.color"
                      >
                        {{ icons.mdiFolder }} </v-icon
                      >{{ referenceLog.job.job_type.folder.name }}
                    </span>
                  </v-hover>
                  <span style="opacity: 0.6">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
                  <v-hover v-slot="{ hover }">
                    <span
                      :class="`text-truncate${hover ? ' text-decoration-underline' : ''}`"
                      :style="{ transition: 'opacity 200ms linear', opacity: hover ? 1 : 0.6 }"
                      style="opacity: 0.6"
                      @click="
                        $router.push({
                          name: 'jobtype-detail',
                          params: {
                            id: referenceLog.job.job_type.folder.id,
                            jobtypeid: referenceLog.job.job_type.id,
                          },
                        })
                      "
                      @click.stop
                    >
                      <v-icon
                        class="mr-1"
                        size="10"
                        :color="referenceLog.job.job_type.color"
                        style="margin-bottom: 2.5px"
                      >
                        {{ icons.mdiCircle }} </v-icon
                      >{{ referenceLog.job.job_type.name }}
                    </span>
                  </v-hover>
                </span>

                <v-hover v-slot="{ hover }">
                  <div
                    class="d-flex align-center overflow-hidden"
                    style="max-width: 100%"
                  >
                    <span
                      :class="`text-subtitle-1 text-truncate font-weight-semibold${
                        hover ? ' text-decoration-underline' : ''
                      }`"
                      style="max-width: 100%; cursor: pointer"
                      @click="
                        $router.push({
                          name: 'job-detail',
                          params: {
                            id: referenceLog.job.id,
                          },
                        })
                      "
                      @click.stop
                    >
                      {{ referenceLog.job.name }}
                    </span>

                    <v-slide-x-transition>
                      <v-icon
                        v-show="hover"
                        class="ml-1"
                        size="14"
                        style="margin-top: 3px"
                      >
                        {{ icons.mdiOpenInNew }}
                      </v-icon>
                    </v-slide-x-transition>
                  </div>
                </v-hover>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content
              class="custom-panel-content overflow-auto mb-2"
              style="max-height: 350px"
            >
              <div class="d-flex flex-column">
                <div
                  v-for="(item, index) in referenceLog.logList"
                  :key="index"
                  class="d-flex align-center px-3 mb-5"
                >
                  <NotificationItemReferenceLog
                    userDetailOnly
                    :referenceLog="item"
                    style="max-width: unset; overflow: visible !important"
                  ></NotificationItemReferenceLog>

                  <v-spacer></v-spacer>

                  <p
                    class="mb-0 text-subtitle-2 text-right text-no-wrap pr-3"
                    :style="{ opacity: 0.6 }"
                  >
                    {{ dateFormat(item.created_at, 11) }}
                  </p>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
  </div>
</template>

<script>
import { categoryMap, filterList } from '@/constants/notification'
import { categoryList } from '@/constants/timeline'
import dateFormat from '@/utils/dateFormat'
import NotificationItemReferenceLog from '@/views/notification/components/NotificationItemReferenceLog.vue'
import { useUtils } from '@core/libs/i18n'
import { mdiCheck, mdiCircle, mdiFilter, mdiFolder, mdiOpenInNew } from '@mdi/js'
import { computed, inject, ref, watch } from 'vue'

export default {
  components: {
    NotificationItemReferenceLog,
  },
  setup() {
    const referenceLogsList = inject('referenceLogsList')
    const filterByName = ref('')

    const { t } = useUtils()

    const handleFetchParentData = () => {
      console.log('SELECETED, REQUEST DATA FROM PARENT')
    }

    // ! Taken Directly from Timeline.vue, by @maulanafanny
    const filterUserMenu = ref(false)
    const filterCategoryMenu = ref(false)
    const filterCategory = ref(categoryList.map(category => category.id))
    const isFilterCategoryExist = computed(
      () => filterCategory.value.length !== categoryList.length,
    )
    const isFilterExist = computed(() => isFilterCategoryExist.value)
    const searchedCategory = computed(() => {
      if (filterByName.value === '') {
        return categoryList
      }

      return categoryList.filter(category =>
        category.label.toLowerCase().includes(filterByName.value.toLowerCase()),
      )
    })

    const handleResetFilter = () => {
      filterCategory.value = categoryList.map(category => category.id)
    }
    const handleSelectCategory = () => {
      if (!isFilterExist.value) filterCategory.value = []
      else filterCategory.value = categoryList.map(category => category.id)

      // handle Parent Component Fetch Data when filter changes
      handleFetchParentData()
    }

    watch(filterUserMenu, () => {
      if (!filterUserMenu.value) {
        // handle Parent Component Fetch Data when filter changes
        handleFetchParentData()
      }
    })

    return {
      // i18n
      t,

      referenceLogsList,
      filterByName,
      filterCategoryMenu,
      filterCategory,
      searchedCategory,

      isFilterExist,
      handleResetFilter,
      handleSelectCategory,
      handleFetchParentData,
      dateFormat,

      // constant
      categoryMap,
      filterList,

      icons: {
        mdiFilter,
        mdiCheck,
        mdiFolder,
        mdiCircle,
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style lang="scss">
.user-detail-activity-search .v-input__control .v-input__slot {
  min-height: 38px !important;
}
</style>
