import { notificationAccessor } from '@/constants/notification'
import {
  archiveAllNotification as archiveAllNotificationQuery,
  archiveAllReadNotification as archiveAllReadNotificationQuery,
  archiveNotification as archiveNotificationQuery,
  countUnreadNotification as countUnreadNotificationQuery,
  countUnreadNotificationThreeGroups as countUnreadNotificationThreeGroupsQuery,
  markAllAsReadNotification as markAllAsReadNotificationQuery,
  notificationByObject as notificationByObjectQuery,
  notificationSettings as notificationSettingsQuery,
  notifications as notificationsQuery,
  readNotification as readNotificationMutation,
  snoozeUnsnoozeNotification as snoozeUnsnoozeNotificationMutation,
  unarchiveNotification as unarchiveNotificationQuery,
  unreadNotification as unreadNotificationMutation,
  updateNotificationSettings as updateNotificationSettingsMutation,
} from '@/graphql/queries'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'
import { apolloClient } from '@/vue-apollo'
import { useDebounceFn } from '@vueuse/core'
import { formatISO } from 'date-fns'
import Vue, { ref } from 'vue'

const useNotification = () => {
  const pagination = ref({
    limit: 20,
    after: null,
  })

  const notificationCount = ref([0, 0, 0])

  const fetchUnreadNotification = async () => {
    apolloClient
      .query({
        query: countUnreadNotificationQuery,
        fetchPolicy: 'no-cache',
        variables: {
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(({ data }) => {
        store.dispatch(
          'updateUnreadNotificationBadge',
          data.countUnreadNotification.countUnreadNotification,
        )
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const fetchUnreadNotificationThreeGroups = async () => {
    apolloClient
      .query({
        query: countUnreadNotificationThreeGroupsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(({ data }) => {
        store.dispatch('updateUnreadNotificationThreeGroups', {
          countUnreadNormalNotification:
            data.countUnreadNotificationThreeGroups.countUnreadNormalNotification,
          countUnreadSnoozedNotification:
            data.countUnreadNotificationThreeGroups.countUnreadSnoozedNotification,
          countUnreadArchivedNotification:
            data.countUnreadNotificationThreeGroups.countUnreadArchivedNotification,
        })
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const fetchNotifications = async (filter, fetchMore, lastCreated) => {
    if (fetchMore) pagination.value.after = lastCreated
    else {
      store.dispatch('updateNotificationLoading', true)
      pagination.value.after = formatISO(Date.now())
    }

    apolloClient
      .query({
        query: notificationsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          filter: filter,
          paginationv2: pagination.value,
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(({ data }) => {
        notificationCount.value[filter.group] = data.notifications.count

        if (!fetchMore)
          return store.dispatch('updateNotificationList', {
            type: filter.group,
            data: data.notifications.objectOfNot,
          })

        store.dispatch('updatePushNotificationList', {
          type: filter.group,
          data: data.notifications.objectOfNot,
        })
      })
      .catch(err => {
        errorHandling(err)
      })
      .finally(() => {
        store.dispatch('updateNotificationLoading', false)
      })
  }

  const fetchNotificationByObject = (objectId, objectType, filter, pagination) => {
    return new Promise((resolve, reject) =>
      apolloClient
        .query({
          query: notificationByObjectQuery,
          fetchPolicy: 'no-cache',
          variables: {
            objectId: objectId,
            objectType: objectType,
            filter: filter,
            paginationv2: pagination,
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(({ data }) => {
          resolve(data.notificationsByObject)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        }),
    )
  }

  const readNotification = async (objectId, objectType, notificationType) => {
    apolloClient
      .mutate({
        mutation: readNotificationMutation,
        fetchPolicy: 'no-cache',
        variables: {
          objectId: objectId,
          objectType: objectType,
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(() => {
        store.dispatch('updateReadNotification', {
          objectId: objectId,
          notificationType: notificationType,
        })
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const unreadNotification = async (objectId, objectType, notificationType) => {
    apolloClient
      .mutate({
        mutation: unreadNotificationMutation,
        fetchPolicy: 'no-cache',
        variables: {
          objectId: objectId,
          objectType: objectType,
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(() => {
        store.dispatch('updateUnreadNotification', {
          objectId: objectId,
          notificationType: notificationType,
        })
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const snoozeUnsnoozeNotification = async (objectId, objectType, snoozeTime) => {
    console.log(snoozeTime)

    apolloClient
      .mutate({
        mutation: snoozeUnsnoozeNotificationMutation,
        fetchPolicy: 'no-cache',
        variables: {
          snoozedAt: snoozeTime,
          objectId: objectId,
          objectType: objectType,
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(() => {
        if (snoozeTime) {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mensnooze notifikasi!',
          })

          return store.dispatch('updateSnoozeNotification', {
            objectId: objectId,
          })
        }

        store.dispatch('updateUnsnoozeNotification', {
          objectId: objectId,
        })

        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil men-unsnooze notifikasi!',
        })
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const archiveNotification = async (objectId, objectType, notificationType) => {
    apolloClient
      .query({
        query: archiveNotificationQuery,
        fetchPolicy: 'no-cache',
        variables: {
          objectId: objectId,
          objectType: objectType,
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(() => {
        store.dispatch('updateArchiveNotification', {
          objectId: objectId,
          notificationType: notificationType,
        })

        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengarsipkan notifikasi!',
        })
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const unarchiveNotification = async (objectId, objectType, notificationType) => {
    apolloClient
      .query({
        query: unarchiveNotificationQuery,
        fetchPolicy: 'no-cache',
        variables: {
          objectId: objectId,
          objectType: objectType,
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(() => {
        store.dispatch('updateUnarchiveNotification', {
          objectId: objectId,
          notificationType: notificationType,
        })

        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil membuka arsip notifikasi!',
        })
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const markAllAsReadNotification = async notificationType => {
    Vue.$dialog({
      title: 'Tandai semua notifikasi sebagai Sudah Terbaca',
      body: 'Apakah anda yakin ingin menandai semua notifikasi sebagai Sudah Terbaca?',
      maxWidth: '410',
    }).then(confirm => {
      if (confirm) {
        apolloClient
          .query({
            query: markAllAsReadNotificationQuery,
            fetchPolicy: 'no-cache',
            variables: {
              workspaceId: store.getters.getCurrentWorkspaceId,
            },
          })
          .then(() => {
            store.dispatch('updateReadAllNotification', { notificationType })

            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil mengupdate setting notifikasi!',
            })
          })
          .catch(err => {
            errorHandling(err)
          })
      }
    })
  }

  const archiveAllNotification = async () => {
    Vue.$dialog({
      title: 'Arsipkan semua notifikasi',
      body: 'Apakah anda yakin ingin mengarsipkan semua notifikasi?',
      maxWidth: '410',
    }).then(confirm => {
      if (confirm) {
        apolloClient
          .query({
            query: archiveAllNotificationQuery,
            fetchPolicy: 'no-cache',
            variables: {
              workspaceId: store.getters.getCurrentWorkspaceId,
            },
          })
          .then(() => {
            // store.dispatch('updateArchiveNotification', {
            //   notificationId: notificationId,
            //   notificationType: notificationType,
            // })
          })
          .catch(err => {
            errorHandling(err)
          })
      }
    })
  }

  const archiveAllReadNotification = async () => {
    Vue.$dialog({
      title: 'Arsipkan semua notifikasi yang sudah terbaca',
      body: 'Apakah anda yakin ingin mengarsipkan semua notifikasi yang sudah terbaca?',
      maxWidth: '410',
    }).then(confirm => {
      if (confirm) {
        apolloClient
          .query({
            query: archiveAllReadNotificationQuery,
            fetchPolicy: 'no-cache',
            variables: {
              workspaceId: store.getters.getCurrentWorkspaceId,
            },
          })
          .then(() => {
            // store.dispatch('updateArchiveNotification', {
            //   notificationId: notificationId,
            //   notificationType: notificationType,
            // })
          })
          .catch(err => {
            errorHandling(err)
          })
      }
    })
  }

  const fetchNotificationSettings = async () => {
    return new Promise((resolve, reject) =>
      apolloClient
        .query({
          query: notificationSettingsQuery,
          fetchPolicy: 'no-cache',
          variables: {
            workspaceId: store.getters.getCurrentWorkspaceId,
          },
        })
        .then(({ data }) => {
          resolve(data.notificationSettings.notification_settings)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        }),
    )
  }

  const updateNotificationSettings = async notificationSettings => {
    apolloClient
      .mutate({
        mutation: updateNotificationSettingsMutation,
        variables: {
          notificationSettings: notificationSettings,
          workspaceId: store.getters.getCurrentWorkspaceId,
        },
      })
      .then(() => {
        Vue.notify({
          title: 'Sukses!',
          text: 'Berhasil mengupdate setting notifikasi!',
        })
      })
      .catch(err => {
        errorHandling(err)
      })
  }

  const listenScrollNotification = useDebounceFn(async (data, filter) => {
    if (notificationCount[filter.group] === 0) return

    if (
      data.target.offsetHeight + data.target.scrollTop >= data.target.scrollHeight - 500 &&
      notificationAccessor[filter.group].state().length <= notificationCount.value[filter.group]
    ) {
      await fetchNotifications(
        filter,
        true,
        notificationAccessor[filter.group].state()[
          notificationAccessor[filter.group].state().length - 1
        ].notification.created_at,
      )
    }
  }, 200)

  return {
    notificationCount,

    fetchUnreadNotification,
    fetchUnreadNotificationThreeGroups,
    fetchNotifications,
    fetchNotificationByObject,

    readNotification,
    unreadNotification,
    snoozeUnsnoozeNotification,
    archiveNotification,
    unarchiveNotification,
    markAllAsReadNotification,
    archiveAllNotification,
    archiveAllReadNotification,

    fetchNotificationSettings,
    updateNotificationSettings,

    listenScrollNotification,
  }
}

export default useNotification
