<template>
  <v-dialog
    class="automation-dialog"
    v-model="isOpen"
    max-width="1500"
    scrollable
    :persistent="isCreating || isEditing"
  >
    <v-card height="90vh">
      <v-card-title class="d-flex justify-space-between py-4 px-6">
        <span class="text-h6 font-bold"> Automations </span>
        <v-icon
          style="width: 32px; height: 32px"
          @click="handleCloseAutomation()"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text class="d-flex flex-column py-4 px-6 overflow-hidden">
        <v-row
          dense
          style="margin: 0 !important"
        >
          <v-tabs
            style="box-shadow: none !important"
            v-model="tabs"
          >
            <v-tab
              :class="`${!isCreating && !isEditing ? 'ml-auto ' : 'd-none'}`"
              style="width: 100%"
              key="0"
            >
              <span>Manage</span>
            </v-tab>

            <v-tab
              :class="`${!isCreating && !isEditing ? 'mr-auto' : 'd-none'}`"
              style="width: 100%"
              key="1"
            >
              <span>History</span>
            </v-tab>

            <v-tab
              :class="`${isCreating ? 'mx-auto' : 'd-none'}`"
              style="width: 100%"
              key="2"
            >
              <span>Create Automation</span>
            </v-tab>

            <v-tab
              :class="`${isEditing ? 'mx-auto' : 'd-none'}`"
              style="width: 100%"
              key="3"
            >
              <span>Edit Automation</span>
            </v-tab>
          </v-tabs>
        </v-row>

        <v-row
          class="pb-4 px-1"
          style="margin: 0 !important"
          dense
        >
          <v-divider />
        </v-row>

        <v-row
          class="align-center pb-4"
          style="margin: 0 !important"
          dense
        >
          <template v-if="!isCreating && !isEditing">
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="folderDetail"
                class="text-truncate"
                solo
                flat
                dense
                outlined
                return-object
                item-value="id"
                item-text="name"
                hide-details="auto"
                placeholder="Pilih Folder"
                :value="folderDetail"
                :items="folderList"
              >
                <template #selection="{ item }">
                  <div class="d-flex align-center py-3 px-1 overflow-hidden">
                    <v-icon
                      :color="item.color"
                      class="mr-2"
                      size="18"
                    >
                      {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                    </v-icon>

                    <span class="text-truncate">{{ item.name }}</span>
                  </div>
                </template>

                <template #item="{ item }">
                  <div class="d-flex align-center py-3 px-1 w-full">
                    <v-icon
                      :color="item.color"
                      class="mr-2"
                      size="18"
                    >
                      {{ item.is_public ? icons.mdiFolder : icons.mdiFolderLock }}
                    </v-icon>

                    <span class="text-truncate">{{ item.name }}</span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="jobTypeDetail"
                class="text-truncate"
                solo
                flat
                dense
                outlined
                clearable
                return-object
                height="100%"
                item-value="id"
                item-text="name"
                hide-details="auto"
                placeholder="Pilih Job Type"
                :value="jobTypeDetail"
                :items="jobTypeList"
              >
                <template #selection="{ item }">
                  <div class="d-flex align-center py-3 px-1 overflow-hidden">
                    <v-badge
                      class="mr-1"
                      dot
                      inline
                      :color="item.color"
                    />

                    <v-icon
                      v-if="!item.is_public"
                      size="14"
                      class="mr-1"
                    >
                      {{ icons.mdiLockOutline }}
                    </v-icon>

                    <span class="text-truncate">{{ item.name }}</span>
                  </div>
                </template>

                <template #item="{ item }">
                  <div class="d-flex align-center py-3 px-1 w-full">
                    <v-badge
                      class="mr-1"
                      dot
                      inline
                      :color="item.color"
                    />

                    <v-icon
                      v-if="!item.is_public"
                      size="14"
                      class="mr-1"
                    >
                      {{ icons.mdiLockOutline }}
                    </v-icon>

                    <span class="text-truncate">{{ item.name }}</span>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </template>

          <template v-else>
            <v-col
              cols="12"
              md="1"
            >
              <v-btn
                text
                outlined
                depressed
                style="border-radius: 4px; width: 100%"
                @click="handleAutomationFormClose()"
              >
                <v-icon
                  class="mr-1"
                  size="26"
                >
                  {{ icons.mdiArrowLeftThin }}
                </v-icon>

                <h3 class="font-medium pr-2">Cancel</h3>
              </v-btn>
            </v-col>
          </template>

          <v-spacer />

          <template v-if="!isCreating && !isEditing">
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                depressed
                color="primary"
                style="border-radius: 4px; width: 100%"
                @click="handleOpenCreateAutomation()"
              >
                <h3
                  class="font-medium pl-2"
                  style="color: white !important"
                >
                  Add Automation
                </h3>
              </v-btn>
            </v-col>
          </template>

          <template v-else-if="isCreating">
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                depressed
                color="primary"
                style="border-radius: 4px; width: 100%"
                @click="handleCreateAutomation()"
              >
                <h3
                  class="font-medium pl-2"
                  style="color: white !important"
                >
                  Create Automation
                </h3>
              </v-btn>
            </v-col>
          </template>

          <template v-else-if="isEditing">
            <v-col
              cols="12"
              md="auto"
            >
              <v-btn
                depressed
                color="primary"
                style="border-radius: 4px; width: 100%"
                @click="handleSaveAutomation()"
              >
                <h3
                  class="font-medium pl-2"
                  style="color: white !important"
                >
                  Save Automation
                </h3>
              </v-btn>
            </v-col>
          </template>
        </v-row>

        <v-row
          class="h-full overflow-auto"
          style="max-height: 100%; margin: 0 !important"
          dense
        >
          <v-tabs-items
            class="w-full px-1"
            v-model="tabs"
          >
            <!-- v-scroll.self="handleScroll" -->
            <v-tab-item>
              <v-card
                outlined
                @click="handleOpenEditAutomation()"
              >
                <v-card-title class="d-flex justify-space-between pt-4 pb-2 px-5">
                  <h3>TITLE - AUTOMATION NAME</h3>

                  <v-switch
                    style="margin: 0"
                    hide-details
                    inset
                    @click.stop
                  ></v-switch>

                  <v-btn
                    class="ml-2"
                    icon
                    depressed
                    style="border-radius: 4px"
                  >
                    <v-icon size="20">
                      {{ icons.mdiCogOutline }}
                    </v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="d-flex flex-column pt-2 pb-4 px-5">
                  <v-row
                    style="margin: 0 !important"
                    dense
                  >
                    <v-col
                      cols="12"
                      md="10"
                    >
                      TRIGGER & ACTION - AUTOMATION SUMMARY
                    </v-col>

                    <!-- 
                    <v-col
                      class="d-flex justify-end align-center overflow-hidden"
                      cols="12"
                      md="2"
                    >
                      <span>Created By :</span>

                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-avatar
                            v-bind="attrs"
                            size="26"
                            class="v-avatar-light-bg mx-2 mt-1 elevation-1 cursor-pointer"
                            v-on="on"
                          >
                            <span class="caption"> U </span>
                            <span
                            class="caption"
                            v-else
                          >
                            {{
                              avatarText(
                                notification.referenceLog.created_by.id ===
                                  store.getters.getUserData.id
                                  ? 'U'
                                  : notification.referenceLog.created_by.name,
                              )
                            }}
                          </span>
                          </v-avatar>
                        </template>
                        <span>Anda</span>
                        <span>{{
                        notification.referenceLog.created_by.id === store.getters.getUserData.id
                          ? 'Anda'
                          : notification.referenceLog.created_by.name
                      }}</span>
                      </v-tooltip>

                      <span class="text-truncate">Anda</span>
                    </v-col> -->

                    <v-col
                      class="pt-2"
                      cols="12"
                    >
                      DESCRIPTION - AUTOMATION DESCRIPTION
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item> HISTORY </v-tab-item>

            <v-tab-item>
              <AutomationCreate
                :folder="folderDetail"
                :jobType="jobTypeDetail"
              />
            </v-tab-item>

            <v-tab-item>
              <AutomationEdit
                :folder="folderDetail"
                :jobType="jobTypeDetail"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useRouter } from '@/@core/utils'
import useFolder from '@/composables/useFolder'
import { getJobTypes } from '@/services/jobTypeService'
import {
  mdiArrowLeftThin,
  mdiClose,
  mdiContainEnd,
  mdiContainStart,
  mdiFilterOutline,
  mdiFolder,
  mdiFolderLock,
  mdiLockOutline,
  mdiMinus,
  mdiPlus,
  mdiSourceCommit,
  mdiTuneVertical,
} from '@mdi/js'
import Vue, { onMounted, ref, watch } from 'vue'
import { createFieldMapper } from 'vuex-use-fields'
import AutomationCreate from './AutomationCreate.vue'
import AutomationEdit from './AutomationEdit.vue'

export default {
  components: {
    AutomationCreate,
    AutomationEdit,
  },

  setup() {
    const { fetchFolderDetail } = useFolder()
    const { router } = useRouter()

    const tabs = ref(0)
    const isOpen = ref(false)
    const isCreating = ref(false)
    const isEditing = ref(false)

    const folderDetail = ref()
    const { folderList } = createFieldMapper({
      getter: 'folder/getField',
      setter: 'folder/setField',
    })(['folderList'])
    const jobTypeDetail = ref()
    const jobTypeList = ref()

    const handleCloseAutomation = () => {
      isOpen.value = false
      isCreating.value = false
      isEditing.value = false
      tabs.value = 0
    }

    const handleAutomationFormClose = () => {
      isCreating.value = false
      isEditing.value = false
      tabs.value = 0
    }

    const handleOpenCreateAutomation = () => {
      isCreating.value = true
      tabs.value = 2
    }

    const handleOpenEditAutomation = () => {
      isEditing.value = true
      tabs.value = 3
    }

    onMounted(() => {
      Vue.$openAutomation = async (folder, job_type) => {
        if (folder?.name) folderDetail.value = folder
        else await fetchFolderDetail(folder.id).then(data => (folderDetail.value = data))

        jobTypeDetail.value = undefined
        if (job_type) jobTypeDetail.value = job_type

        jobTypeList.value = await getJobTypes(folder.id, {
          search: '',
          my_jobType: null,
          show_archived: 'IS NOT SET',
          sort: 'name',
          sortType: 'ASC',
        })

        isOpen.value = true
      }
    })

    watch(folderDetail, async () => {
      jobTypeDetail.value = undefined

      jobTypeList.value = await getJobTypes(folderDetail.value.id, {
        search: '',
        my_jobType: null,
        show_archived: 'IS NOT SET',
        sort: 'name',
        sortType: 'ASC',
      })
    })

    return {
      tabs,
      isOpen,
      isEditing,
      isCreating,

      folderDetail,
      folderList,
      jobTypeDetail,
      jobTypeList,

      handleCloseAutomation,
      handleAutomationFormClose,
      handleOpenCreateAutomation,
      handleOpenEditAutomation,

      router,

      icons: {
        mdiMinus,
        mdiClose,
        mdiFolder,
        mdiFolderLock,
        mdiLockOutline,
        mdiArrowLeftThin,

        mdiPlus,
        mdiTuneVertical,
        mdiContainStart,
        mdiFilterOutline,
        mdiContainEnd,
        mdiSourceCommit,
      },
    }
  },
}
</script>

<style></style>
