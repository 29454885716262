<template>
  <v-slide-y-transition>
    <v-navigation-drawer
      v-if="isOpen"
      class="overflow-hidden d-flex flex-column px-1"
      v-model="isOpen"
      right
      fixed
      permanent
      :width="
        rootRoute.fullPath === '/' || isFullview
          ? '100vw'
          : $vuetify.breakpoint.md
          ? '45vw'
          : $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
          ? '100vw'
          : '35vw'
      "
      style="z-index: 203"
    >
      <div
        class="d-flex flex-column overflow-auto"
        style="height: 100vh"
      >
        <app-breadcrumb
          class="mt-6 px-4"
          title="Detail Pengguna"
          hide-breadcrumbs
          :onClickBack="handleBack"
        >
          <template #action>
            <v-icon
              v-if="rootRoute?.fullPath !== '/'"
              v-show="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? false : true"
              class="primary--text mr-4"
              @click="handleOpenFullview()"
            >
              {{ isFullview ? icons.mdiFullscreenExit : icons.mdiFullscreen }}
            </v-icon>
          </template>
        </app-breadcrumb>

        <UserDetail
          v-bind="componentProps"
          :isFullview="isFullview"
        />
      </div>
    </v-navigation-drawer>
  </v-slide-y-transition>
</template>

<script>
import router from '@/router'
import UserDetail from '@/views/user/UserDetail.vue'
import { mdiFullscreen, mdiFullscreenExit } from '@mdi/js'
import Vue, { onMounted, ref } from 'vue'
import AppBreadcrumb from './AppBreadcrumb.vue'

export default {
  components: {
    UserDetail,
    AppBreadcrumb,
  },
  setup() {
    const isOpen = ref(false)
    const rootRoute = ref(router.currentRoute)
    const componentProps = ref()
    const isFullview = ref(false)

    const handleBack = () => {
      isOpen.value = false

      if (rootRoute.value?.name) return window.history.pushState({}, null, rootRoute.value.fullPath)

      router.push({ name: 'workspace-root' })
    }

    const handleOpenFullview = () => {
      isFullview.value = !isFullview.value
    }

    onMounted(() => {
      Vue.$drawer = props => {
        if (!isOpen.value) rootRoute.value = router.currentRoute

        componentProps.value = props

        isOpen.value = true
      }

      if (rootRoute.value?.fullPath === '/') isFullview.value = true
    })

    return {
      isOpen,
      rootRoute,
      componentProps,
      isFullview,
      handleBack,
      handleOpenFullview,

      icons: {
        mdiFullscreen,
        mdiFullscreenExit,
      },
    }
  },
}
</script>
