import { ref } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import { referenceLogs as queryReferenceLogs, referenceLogsByUser } from '@/graphql/queries'
import { apolloClient } from '@/vue-apollo'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'

const useTimeline = objectId => {
  const timelineLoading = ref(false)
  const timelineLoadingPagination = ref(false)
  const timelines = ref([])
  const timelineCount = ref(0)
  const paramsData = ref(objectId)
  const pagination = ref({
    offset: 0,
    limit: 20,
  })

  const fetchReferenceLogs = async (fetchMore, paramsFilter) => {
    timelineLoading.value = true

    if (fetchMore) {
      pagination.value.offset += 10
    } else {
      pagination.value.offset = 0
    }

    apolloClient
      .query({
        query: queryReferenceLogs,
        fetchPolicy: 'no-cache',
        variables: {
          ...paramsData.value,
          ...paramsFilter,
          pagination: pagination.value,
        },
      })
      .then(({ data }) => {
        const {
          referenceLogs: { referenceLogs, count },
        } = data

        if (!fetchMore) {
          timelines.value = referenceLogs
        } else {
          timelines.value.push(...referenceLogs)
        }

        timelineCount.value = count
      })
      .catch(err => {
        errorHandling(err)
      })
      .finally(() => {
        timelineLoading.value = false
      })
  }

  const fetchReferenceLogsByUser = (pagination, user_id, filter, search) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: referenceLogsByUser,
          fetchPolicy: 'no-cache',
          variables: {
            workspaceId: store.getters.getCurrentWorkspaceId,
            pagination: pagination,
            createdBy: [user_id],
            categoryId: filter,
            searchJobName: search,
          },
        })
        .then(({ data }) => {
          resolve(data.referenceLogsByUser)
        })
        .catch(err => {
          reject(err)
          errorHandling(err)
        })
    })
  }

  const listenScrollFetchMoreTimeline = useDebounceFn(async data => {
    if (
      data.target.offsetHeight + data.target.scrollTop >= data.target.scrollHeight - 100 &&
      pagination.value.offset <= timelineCount.value
    ) {
      pagination.value.offset += 10
      timelineLoadingPagination.value = true
      await fetchReferenceLogs(true)
      timelineLoadingPagination.value = false
    }
  }, 200)

  return {
    timelines,
    timelineCount,
    timelineLoading,
    timelineLoadingPagination,

    fetchReferenceLogs,
    fetchReferenceLogsByUser,
    listenScrollFetchMoreTimeline,
  }
}

export default useTimeline
